import { action, computed, decorate, observable } from "mobx";
import { validateTimestamps } from "../utils/utils";
import { toast } from "react-toastify";

class AssetOverrideDialogStore {
  constructor(jaredApi, videoAssetOverridesStore) {
    this.jaredApi = jaredApi;
    this.videoAssetOverridesStore = videoAssetOverridesStore;
    this.resetValues();
  }

  resetValues() {
    this.isOpen = false;
    this.saving = false;

    this.id = null;
    this.gamePkValue = "";
    this.assetNameValue = "";
    this.from = "";
    this.to = "";
    this.feedType = "";
    this.timecodeOffsetOverride = "";
  }

  async editOverride(assetOverride) {
    // required fields
    this.id = assetOverride.id;
    this.gamePkValue = assetOverride.gamePk.toString();
    this.feedType = assetOverride.feedType;
    this.assetNameValue = assetOverride.override;

    // optional fields
    this.from = assetOverride.from || "";
    this.to = assetOverride.to || "";
    this.timecodeOffsetOverride = assetOverride.timecodeOffsetOverride || "";

    this.isOpen = true;
  }

  setGamePk(newGamePk) {
    if (isNaN(newGamePk)) {
      return;
    }

    this.gamePkValue = newGamePk;
  }

  get isValid() {
    return (
      this.gamePkValue.trim() !== "" &&
      this.feedType.trim() !== "" &&
      this.assetNameValue.trim() !== ""
    );
  }

  async submitOverride() {
    const timestampError = validateTimestamps(this.from, this.to);
    if (timestampError) {
      this.errorMessage = timestampError;
      return;
    }

    this.saving = true;
    const assetOverride = {
      id: this.id,
      gamePk: this.gamePkValue,
      override: this.assetNameValue,
      fromTS: this.from,
      toTS: this.to,
      feedType: this.feedType,
      timecodeOffsetOverride: this.timecodeOffsetOverride
    };
    this.jaredApi
      .upsertVideoAssetOverrides(assetOverride)
      .then(r => {
        if (r.data.success) {
          const verbiage = this.id ? "Updated" : "Created";
          toast.success(
            `${verbiage} override for asset: ${this.assetNameValue}`,
            { position: "bottom-left" }
          );
          this.resetValues();
          this.videoAssetOverridesStore.loadOverrides();
        } else {
          const verbiage = this.id ? "update" : "create";
          toast.error(`Failed to ${verbiage} asset: ${r.data.message}`, {
            position: "bottom-left"
          });
        }
      })
      .finally(() => (this.saving = false));
  }
}

decorate(AssetOverrideDialogStore, {
  id: observable,
  feedType: observable,
  isOpen: observable,
  gamePkValue: observable,
  assetNameValue: observable,
  from: observable,
  to: observable,
  timecodeOffsetOverride: observable,
  errorMessage: observable,
  saving: observable,
  isValid: computed,
  editOverride: action,
  submitOverride: action
});

export default AssetOverrideDialogStore;
